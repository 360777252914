import Vue from 'vue';
import Component from 'vue-class-component';
// eslint-disable-next-line import/extensions
import EstateIllustration from '@/assets/illustrations/estate.svg?inline';

@Component({
  components: {
    EstateIllustration,
  },
})

export default class LandingAccessible extends Vue {

}
