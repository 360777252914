import Vue from 'vue';
import Component from 'vue-class-component';
import Logo from '@/components/common/logo/Logo.vue';
// eslint-disable-next-line import/extensions
import FacebookIcon from '@/assets/icons/facebook.svg?inline';
// eslint-disable-next-line import/extensions
import InstagramIcon from '@/assets/icons/instagram.svg?inline';
// eslint-disable-next-line import/extensions
import YouTubeIcon from '@/assets/icons/youtube.svg?inline';
// eslint-disable-next-line import/extensions
import LinkedInIcon from '@/assets/icons/linkedin.svg?inline';
import { email, phone } from '../../../../../whitelabel.config';

@Component({
  components: {
    Logo,
    FacebookIcon,
    InstagramIcon,
    YouTubeIcon,
    LinkedInIcon,
  },
})

export default class LandingFooter extends Vue {
  email = email;
  phone = phone;
}
